"use client";

import { Stack } from "components/layouts/Stack";
import { Card } from "components/widgets/Card";
import { CardSection } from "components/widgets/Card/CardSection";
import { H2 } from "components/widgets/H";
import { LinkButton } from "components/widgets/LinkButton";

export const SignUpNavigationCard = () => {
  return (
    <div className="mr-auto ml-auto text-center w-80">
      <Card>
        <CardSection>
          <Stack spacing="xl">
            <H2>まだアカウントを持っていない方</H2>
            <LinkButton block href="/delivery_clients/new">
              新規登録画面へ
            </LinkButton>
          </Stack>
        </CardSection>
      </Card>
    </div>
  );
};
