"use client";

import { ActivityButton } from "components/forms/Button/ActivityButton";
import { CheckBoxLabel } from "components/forms/CheckBox/CheckBoxLabel";
import { InputField } from "components/forms/InputField";
import { TextField } from "components/forms/TextField";
import { PasswordField } from "components/forms/TextField/PasswordField";
import { Stack } from "components/layouts/Stack";
import { Card } from "components/widgets/Card";
import { CardSection } from "components/widgets/Card/CardSection";
import { H2 } from "components/widgets/H";
import { APIError } from "libs/api/client";
import { FEATURES, isEnableFeature } from "libs/features";
import { useTransaction } from "libs/hooks/useTransaction";
import { useRouter } from "next/navigation";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecaptchaV2 } from "react-hook-recaptcha-v2";
import { login } from "../client_apis";

type FormDataType = {
  email: string;
  password: string;
  remember_me: string;
  recaptcha_response_token: string | null;
};

const defaultValues: FormDataType = {
  email: "",
  password: "",
  remember_me: "",
  recaptcha_response_token: null,
};

export const LoginFormCard = () => {
  const router = useRouter();
  const [error, setError] = useState<
    null | "invalid" | "locked" | "network" | "invitation_inactive"
  >(null);
  const errorMessage = useMemo(() => {
    if (error == null) {
      return undefined;
    } else if (error === "invalid") {
      return "メールアドレスかパスワードに誤りがあります";
    } else if (error === "network") {
      return "通信エラーが発生しました";
    } else if (error === "invitation_inactive") {
      return "登録済みなため、ログインをお願いします";
    }
  }, [error]);
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormDataType>({ shouldUnregister: false, defaultValues });

  const formFullFilled =
    watch("email").length > 0 &&
    watch("password").length > 0 &&
    // テスト環境では、recaptcha_response_token が null でも送信できるようにする
    (!isEnableFeature(FEATURES.TEST)
      ? watch("recaptcha_response_token") != null
      : true);

  // reCAPTCHA v2
  const handleReCAPTCHAChange = useCallback(
    (token: string | null) => {
      setValue("recaptcha_response_token", token);
    },
    [setValue]
  );

  const { recaptchaRef } = useRecaptchaV2({
    sitekey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || "",
    size: "normal",
    callback: handleReCAPTCHAChange,
    expiredCallback: () => {
      setValue("recaptcha_response_token", null);
    },
  });

  const [status, handleSubmitImpl] = useTransaction(
    async (props: typeof defaultValues) => {
      setError(null);
      // テスト環境では、recaptcha_response_token が null でも送信できるようにする
      if (
        !isEnableFeature(FEATURES.TEST) &&
        props.recaptcha_response_token == null
      ) {
        return;
      }
      try {
        await login({
          delivery_client: {
            email: props.email,
            password: props.password,
            remember_me: props.remember_me === "1",
          },
          recaptcha_response_token: props.recaptcha_response_token || undefined,
        });
      } catch (e) {
        if (e instanceof APIError) {
          if (e.type === "ApiError::DeliveryClient::AccountLocked") {
            router.push("/account_locked");
          } else if (e.status === 401) {
            setError("invalid");
          } else {
            setError("network");
          }
        }

        return;
      }

      router.push("/delivery_requests");

      await new Promise((_) => {});
    }
  );

  return (
    <div className="mr-auto ml-auto text-center w-80">
      <form onSubmit={handleSubmit(handleSubmitImpl)}>
        <Card>
          <CardSection>
            <Stack spacing="xl">
              <H2>ログイン</H2>
              {error && (
                <p className="bg-alertLight2 border border-solid border-alert rounded-sm text-alert text-xs m-0 py-sm px-md text-center">
                  {errorMessage}
                </p>
              )}
              <Fragment>
                <InputField
                  error={errors.email}
                  size="xs"
                  title="メールアドレス"
                  block
                >
                  <TextField
                    block
                    error={errors.email}
                    {...register("email", { required: true })}
                    placeholder="deliveryx@example.com"
                    type="email"
                  />
                </InputField>
                <InputField
                  error={errors.password}
                  rightItem={
                    <div className="flex justify-end">
                      <a
                        href="https://api.crewexp.com/contact-form/client"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="text-primary no-underline text-sm cursor-pointer hover:text-primary active:text-primary visited:text-primary"
                      >
                        パスワードを忘れた場合
                      </a>
                    </div>
                  }
                  size="xs"
                  title="パスワード"
                  block
                >
                  <PasswordField
                    block
                    error={errors.password}
                    {...register("password", { required: true })}
                    placeholder="・・・・・・・・"
                  />
                </InputField>
              </Fragment>
              <CheckBoxLabel {...register("remember_me", {})} value="1" block>
                ログイン状態を保持
              </CheckBoxLabel>
              <div ref={recaptchaRef} />
              <ActivityButton
                block={true}
                disabled={!formFullFilled}
                size="md"
                buttonTheme="primary"
                type="submit"
                working={status.running}
              >
                OK
              </ActivityButton>
            </Stack>
          </CardSection>
        </Card>
      </form>
    </div>
  );
};
