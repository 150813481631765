import { request } from "libs/openapi";

type LoginParams = {
  delivery_client: {
    email: string;
    password: string;
    remember_me: boolean;
  };
  recaptcha_response_token?: string;
};

export const login = async (params: LoginParams) => {
  let payload: {
    sessions: {
      email: string;
      password: string;
      remember_me: boolean;
    };
    recaptcha_response_token?: string;
  } = {
    sessions: {
      email: params.delivery_client.email,
      password: params.delivery_client.password,
      remember_me: params.delivery_client.remember_me,
    },
    recaptcha_response_token: params.recaptcha_response_token,
  };
  await request("post", "/admin/delivery_clients/sessions", {
    body: payload,
  });
};
