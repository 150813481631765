import { Colors } from "components/colors";
import { radius } from "libs/styles/variables";
import {
  forwardRef,
  Fragment,
  InputHTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import Check from "./check.icon.svg";
import Minus from "./minus.icon.svg";

type Props = {
  defaultValue?: any;
  indeterminate?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const CheckBox = memo(
  forwardRef<HTMLInputElement, Props>(
    ({ className, indeterminate, id, ...otherProps }, ref) => {
      const inputRef = useRef<HTMLInputElement | null>(null);
      const handleRef = useCallback(
        (element: HTMLInputElement | null) => {
          if (ref) {
            if (typeof ref === "function") {
              ref(element);
            } else {
              ref.current = element;
            }
          }
          inputRef.current = element;
        },
        [ref]
      );
      const [inputId] = useState(
        () => id || `e${Math.floor(Math.random() * 1e10)}`
      );
      useEffect(() => {
        if (!inputRef.current) return;
        inputRef.current.indeterminate = Boolean(indeterminate);
      }, [indeterminate]);

      return (
        <Fragment>
          <InputWrapper
            ref={handleRef}
            type="checkbox"
            {...otherProps}
            id={inputId}
          />
          <Base htmlFor={inputId}>
            <CheckWrapper />
            <Indeterminate />
          </Base>
        </Fragment>
      );
    }
  )
);
CheckBox.displayName = "CheckBox";

const InputWrapper = styled.input`
  position: fixed;
  top: -999em;

  &:checked + label {
    background: ${Colors.primaryLight2};
    border-color: ${Colors.primary};

    svg:first-child {
      opacity: 1;
    }
  }

  &:indeterminate + label {
    background: ${Colors.primaryLight2};
    border-color: ${Colors.primary};

    svg:first-child {
      opacity: 0;
    }

    svg:nth-child(2) {
      opacity: 1;
    }
  }
`;

const Base = styled.label`
  border: 2px solid ${Colors.black87};
  border-radius: ${radius.sx};
  box-sizing: content-box;
  display: inline-block;
  flex-shrink: 0;
  height: 14px;
  margin: 0;
  position: relative;
  width: 14px;

  &::after {
    content: "";
    display: block;
    height: 100%;
    transform: scale(1.5);
    transform-origin: 50% 50%;
    width: 100%;
  }
`;

const IconStyle = css`
  fill: ${Colors.primary};
  height: 14px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 14px;
`;

const CheckWrapper = styled(Check)`
  ${IconStyle}
`;

const Indeterminate = styled(Minus)`
  ${IconStyle}
`;
